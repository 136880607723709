export default [
  {
    title: "UNS",
    icon: "mdi-hexagon-multiple",
    to: "/uns",
  },
  {
    title: "Customers",
    icon: "mdi-account-group-outline",
    to: "/customers",
    children: [
      {
        title: "SAAS",
        icon: "mdi-package-variant",
        to: "/rentals",
      },
      {
        title: "Projects",
        icon: "mdi-progress-wrench",
        to: "/projects",
      },
      {
        title: "Product",
        icon: "mdi-cube-outline",
        to: "/products",
      },
    ],
  },
];
