<script setup lang="ts">
import { ref, watch } from "vue";
import sidebarItems from "./sidebarItems";
const { status, data, signIn, signOut } = useSession();

const sidebarMenu = ref(sidebarItems);
</script>

<template>
  <div>
    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <div class="scrollnavbar">
      <div class="profile">
        <div class="profile-pic">
          <v-avatar size="45">
            <img :src="data?.user?.image" width="50" alt="Julia" />
          </v-avatar>
        </div>
        <div class="profile-name">
          <h5>{{ data?.user?.email || 'unknown' }}</h5>
        </div>
      </div>
      <v-list class="pa-4">
        <!-- ---------------------------------------------- -->
        <!---Menu Loop -->
        <!-- ---------------------------------------------- -->
        <template v-for="(item, i) in sidebarMenu" :key="i">
          <!-- ---------------------------------------------- -->
          <!---Single Item-->
          <!-- ---------------------------------------------- -->
          <v-list-item :to="item.to" rounded="lg" class="mb-1">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>

          <!-- ---------------------------------------------- -->
          <!---Sub Items (if available)-->
          <!-- ---------------------------------------------- -->
          <v-list v-if="item.children" class="ml-4">
            <v-list-item
              v-for="(subItem, j) in item.children"
              :key="`${i}-${j}`"
              :to="subItem.to"
              rounded="lg"
              class="mb-1"
            >
              <template v-slot:prepend>
                <v-icon :icon="subItem.icon"></v-icon>
              </template>
              <v-list-item-title v-text="subItem.title"></v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-list>
    </div>
  </div>
</template>