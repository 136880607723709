<script setup>
const { status, data, signIn, signOut } = useSession();
const loggedIn = computed(() => status.value === 'authenticated')
async function handleSignIn() {
  await signIn()
}
async function handleSignOut() {
  try {
    const config = useRuntimeConfig()
    // Sins we are useing auth0 we need to cleas session infor so we are being loged out properly
    // Else we need to logout using the logout function provided by the @auth0/auth0-spa-js.
    // But in this case we just do the clear manually.
    localStorage.clear() // clear local storage here
    sessionStorage.clear() // Clear session
    await signOut() // Signout

    
    // Redirect to Auth0 logout endpoint
    const auth0Domain = config.public.AUTH0_DOMAIN; // replace with your Auth0 domain
    const clientId = config.public.AUTH0_CLIENT_ID; // replace with your Auth0 client ID
    const returnTo = window.location.origin; // or a specific URL you want to redirect to after logout

    window.location.href = `https://${auth0Domain}/v2/logout?client_id=${clientId}&returnTo=${encodeURIComponent(returnTo)}`;
      
  } catch (error) {
    console.log( error.message)
  }
}
const userprofile = ref([
  // {
  //   title: "My Profile",
  //   desc: "Account Settings",
  // },
  // {
  //   title: "My Inbox",
  //   desc: "Messages & Emails",
  // },
  // {
  //   title: "My Tasks",
  //   desc: "To-do and Daily Tasks",
  // },
]);
</script>

<template>
  <v-menu anchor="bottom end" origin="auto" min-width="300">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="pa-0 px-1"
        elevation="0"
        color="transparent"
        plain
        :ripple="false"
      >
        <v-avatar size="35">
          <img :src=data?.user?.image width="35" alt="Julia" />
        </v-avatar>
      </v-btn>
    </template>

    <v-list class="pa-6" elevation="10" rounded="lg">
      <v-list-item
        class="pa-3 mb-2"
        v-for="(item, i) in userprofile"
        :key="i"
        :value="item"
        :title="item.title"
        :subtitle="item.desc"
        rounded="lg"
      >
      </v-list-item>
      <v-btn block color="secondary" variant="tonal" class="mt-4 py-4" v-if="status === 'unauthenticated'" @click="() => sighandleSignInIn()">Sign in</v-btn>
      <v-btn block color="secondary" variant="tonal" class="mt-4 py-4" v-if="status === 'authenticated'" @click="() => handleSignOut()">Sign out</v-btn>
    </v-list>
  </v-menu>
</template>
